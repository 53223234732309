<template>
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col>
              <v-row dense>
                <h2>{{ $t("pages.locations.form.existingSolutionsHeader") }}</h2>
              </v-row>
              <v-row dense>
                <v-col cols="9">
                  <v-row dense>
                    <h3>{{ $t("pages.locations.form.templateName") }}</h3>
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-row dense>
                    <h3>{{ $t("pages.locations.form.amount") }}</h3>
                  </v-row>
                </v-col>
              </v-row>
              <v-row :key="solutionTemplate.solutionTemplate.id" v-for="solutionTemplate in mappedExistingSolutionTemplates" dense>
                <v-col cols="9">
                  <v-row dense>
                    {{ solutionTemplate.solutionTemplate.name }}
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-row dense>
                    {{ solutionTemplate.count }}
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <h2>{{ $t("pages.locations.form.createSolutionsHeader") }}</h2>
              <v-row :key="solutionTemplateState.id" v-for="solutionTemplateState in solutionTemplateStates" dense>
                <v-col cols="6" align-self="center">
                  {{ solutionTemplates.find(x => x.id === solutionTemplateState.id)?.name }}
                </v-col>

                <v-col cols="2" align-self="center">
                  <v-row dense align-content="center" justify="end">
                    <v-btn
                      icon
                      color="primary"
                      @click="() => {updateSolutionTemplatesToAdd(solutionTemplateState.id, 1)}"
                    >
                      <v-icon class="black--text">mdi-plus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="2" align-self="center">
                  <v-text-field
                    type="number"
                    hide-spin-buttons
                    hide-details
                    :value="solutionTemplateState.amount"
                    @input="
                      ($value) =>
                        onAmountInput($value, solutionTemplateState)
                    "
                    solo
                    dense
                  />
                </v-col>
                <v-col cols="2" align-self="center">
                  
                  <v-row dense align-content="center">
                    <v-btn
                      icon
                      color="primary"
                      @click="() => {updateSolutionTemplatesToAdd(solutionTemplateState.id, -1)}"
                    >
                      <v-icon class="black--text">mdi-minus</v-icon>
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </template>
  <script>
  
  export default {
    props: {
      location: Object,
      solutionTemplates: Array,
    },  
    data() {
      return {
        solutionTemplateStates: this.solutionTemplates.map(x => { return {id: x.id, amount: 0}}),
      };
    },
    watch: {
      location() {
        this.solutionTemplateStates = this.solutionTemplates.map(x => { return {id: x.id, amount: 0}});
        this.solutionTemplatesToCreateChanged();
      }
    },
    computed: {
      mappedExistingSolutionTemplates() {
        let mappedExistingSolutionTemplates = [];

        if (this.location) {
          this.location.solutions.forEach((solution) => {
            if (!solution.solutionTemplateId) return;

            console.log(mappedExistingSolutionTemplates)
            let existingCount = mappedExistingSolutionTemplates.find(x => x.solutionTemplate.id == solution.solutionTemplateId);
            if (existingCount) {
              existingCount.count++;
            } else {
              mappedExistingSolutionTemplates.push({solutionTemplate: solution.solutionTemplate, count: 1});
            }
          })
        }

        return mappedExistingSolutionTemplates;
      }
    },
    methods: {
      updateSolutionTemplatesToAdd(templateId, amount) {
        let object = this.solutionTemplateStates.find(x => x.id === templateId);
        
        if (object) {
          object.amount = (object.amount + amount) > 0 ? object.amount + amount : 0;
          this.solutionTemplatesToCreateChanged();
        }
      },
      onAmountInput(inputValue, solutionTemplateState) {
        const value = parseFloat(inputValue);
        if (Number.isInteger(value) && value > 0) {
          solutionTemplateState.amount = value;
        } else {
          solutionTemplateState.amount = 0;
        }
        this.solutionTemplatesToCreateChanged();
      },
      solutionTemplatesToCreateChanged() {
        this.$emit('templates_to_create_changed', this.solutionTemplateStates.filter(x => x.amount > 0));
      }
    },
  };
  </script>
  