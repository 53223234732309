<template>
  <div>
  <h4 class="text-h4">
    {{
        !!user.id && user.id !== emptyGuid
          ? $t("pages.users.form.edit.title")
          : $t("pages.users.form.create.title")
    }}
  </h4>
  <v-spacer class="mb-6"></v-spacer>
  <div>
    <v-row no-gutters>
      <v-col col="12" lg="8">
        <form @submit.prevent="submit">
          <v-card elevation="1">
            <v-card-text>
              <v-row no-gutters class="align-start">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("common.fieldNames.name")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="form.name"
                                name="name"
                                type="text"
                                :error-messages="form.errors.name" />
                </v-col>
              </v-row>
              <v-row no-gutters class="align-start">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.email")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="form.email"
                                name="email"
                                type="email"
                                :error-messages="form.errors.email" />
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start"
                     v-if="!user || user.id === emptyGuid">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.password")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="form.password"
                                name="name"
                                type="password"
                                :error-messages="form.errors.password" />
                </v-col>
              </v-row>
              <v-row no-gutters class="align-start mt-4">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.role")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-select :items="computedAvailableRoles"
                            v-model="form.roleId"
                            name="role"
                            :label="$t('pages.users.form.fields.role')"
                            :error-messages="form.errors.roleId" />
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start mt-4"
                     v-if="isBackofficeRoles()">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t(
                        "pages.users.form.fields.supportCaseNotification.title"
                      )
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-checkbox v-model="form.notifyOnNewSupportCase"
                              :label="
                        $t(
                          'pages.users.form.fields.supportCaseNotification.label'
                        )
                      "
                              class="mt-0" />
                </v-col>
              </v-row>
              <v-row no-gutters class="align-start mt-4">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.visibleRegions.title")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <form autocomplete="off" @submit.prevent="() => {}">
                    <v-autocomplete :items="regions"
                                    :disabled="form.isAllRegionsVisible"
                                    v-model="form.regions"
                                    multiple
                                    name="region"
                                    :label="
                          $t('pages.users.form.fields.visibleRegions.label')
                        "
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="form.errors.regions" />
                  </form>
                </v-col>
              </v-row>
              <v-row no-gutters class="align-start">
                <v-col cols="4"> </v-col>
                <v-col cols="8">
                  <v-checkbox v-model="form.isAllRegionsVisible"
                              @change="form.regions = []"
                              :label="$t('pages.users.form.fields.allRegions')"
                              class="mt-0" />
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start mt-4"
                     v-if="isOrganizationRoles()">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.visibleLocations.title")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <location-picker :locations="organizationLocations"
                                   :disabled="form.hasAccessToAllOrganizationLocations"
                                   v-model="form.locations"
                                   multiple
                                   name="location"
                                   :label="
                        $t('pages.users.form.fields.visibleLocations.label')
                      "
                                   :error-messages="form.errors.locations"
                                   @focus="fixSelectAllLocationsCheck">
                    <v-list-item slot="prepend-item"
                                 ripple
                                 @click="toggleSelectAllLocations"
                                 :class="
                          allLocationsSelected &&
                          'primary--text v-list-item--active'
                        ">
                      <v-list-item-action>
                        <v-checkbox :ripple="false"
                                    :value="allLocationsSelected" />
                      </v-list-item-action>
                      <v-list-item-title>
                        {{
                          $t("common.actions.selectAll")
                        }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" />
                  </location-picker>
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start"
                     v-if="isOrganizationRoles()">
                <v-col cols="4"> </v-col>
                <v-col cols="8">
                  <v-checkbox v-model="form.hasAccessToAllOrganizationLocations"
                              @change="form.locations = []"
                              :label="$t('pages.users.form.fields.allLocations')"
                              class="mt-0" />
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start mt-4"
                     v-if="isSupportRole() && organizations?.length">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.visibleOrganizations.title")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <form autocomplete="off" @submit.prevent="() => {}">
                    <v-autocomplete :items="organizations"
                                    v-model="form.organizations"
                                    multiple
                                    name="organization"
                                    :label="
                          $t(
                            'pages.users.form.fields.visibleOrganizations.label'
                          )
                        "
                                    item-text="name"
                                    item-value="id"
                                    :error-messages="form.errors.organizations" />
                  </form>
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start mt-4"
                     v-if="isSystemRole()">
                <v-col cols="4">
                  <v-subheader>
                    {{
                      $t("pages.users.form.fields.groups.title")
                    }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <form autocomplete="off" @submit.prevent="() => {}">
                    <v-autocomplete :items="availableGroups"
                                    v-model="form.groups"
                                    multiple
                                    clearable
                                    name="group"
                                    :label="
                          $t(
                            'pages.users.form.fields.groups.label'
                          )
                        "
                                    item-text="name"
                                    item-value="name"
                                    :error-messages="form.errors.groups"
                                    :search-input.sync="groupSearchInput"
                                    @keydown.enter.prevent="addNewGroup"
                    >
                                    
                      <template v-slot:no-data>
                        <v-list-item style="width: min-content">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                $t("pages.users.form.fields.groups.noMatches")
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{
                                $t("pages.users.form.fields.groups.likeToAdd")
                              }}
                              {{ groupSearchInput }}
                              {{
                                $t("pages.users.form.fields.groups.asNewType")
                              }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </form>
                </v-col>
              </v-row>
              <v-row no-gutters
                     class="align-start mt-4"
                     v-if="isOrganizationChanged()">
                <v-col cols="4">
                  <v-subheader>
                    {{ $t("pages.users.form.fields.yourPassword") }}
                  </v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field v-model="form.userPassword"
                                name="userPassword"
                                type="password"
                                autocomplete="current-password"
                                :error-messages="form.errors.userPassword" />
                </v-col>
              </v-row>
              <v-alert v-if="errors.alert" type="error" class="mt-2 mb-0">
                {{ errors.alert }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <flash-messages />
              <v-spacer></v-spacer>
              <v-btn outlined
                     text
                     color="error"
                     :disabled="sending || form.processing"
                     @click="deactivate"
                     v-if="user.id && user.id !== emptyGuid && !user.disabledAt">{{ $t("pages.users.form.actions.deactivate") }}</v-btn>
              <v-btn outlined
                     text
                     color="warning"
                     :disabled="sending || form.processing"
                     @click="reactivate"
                     v-else-if="
                    user.id && user.id !== emptyGuid && user.disabledAt
                  ">{{ $t("pages.users.form.actions.reactivate") }}</v-btn>
              <v-btn type="submit"
                     outlined
                     color="primary"
                     :disabled="form.processing">{{ $t("pages.users.form.actions.save") }}</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </div>
</div>
</template>

<script>
import { appLayout } from "@/util/layout";
import FlashMessages from "@/Shared/FlashMessages";
import LocationPicker from "@/Components/LocationPicker";
import { systemRoles } from "../../util/roles";

const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

export default {
  layout: appLayout({ title: "pages.users.form.pageTitle" }),
  props: {
    roles: Array,
    user: Object,
    organizations: Array,
    locations: Array,
    regions: Array,
    groups: Array,
    errors: Object,
  },
  components: {
    FlashMessages,
    LocationPicker,
  },
  data() {
    return {
      sending: false,
      organizationIds: this.user.organizations?.map((x) => x.id) || [],
      form: this.$inertia.form({
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        password: null,
        roleId: this.user.roleId,
        disabledAt: null,
        locations: this.user.locations?.map((x) => x.id) || [],
        regions: this.user.regions?.map((x) => x.id) || [],
        organizations: this.user.organizations?.map((x) => x.id) || [],
        groups: this.user.groups?.map((x) => x.name) || [],
        userPassword: null,
        hasAccessToAllOrganizationLocations:
          this.user.hasAccessToAllOrganizationLocations,
        isAllRegionsVisible: this.user.isAllRegionsVisible,
        notifyOnNewSupportCase: this.user.notifyOnNewSupportCase,
      }),
      availableRoles: this.roles,
      emptyGuid: EMPTY_GUID,
      groupSearchInput: "",
      availableGroups: this.groups
    };
  },
  computed: {
    organizationLocations() {
      return this.locations.filter((x) =>
        this.organizationIds.includes(x.organizationId)
      );
    },
    allLocationsSelected() {
      return this.organizationLocations.every((l) =>
        this.form.locations.includes(l.id)
      );
    },
    computedAvailableRoles() {
      return this.availableRoles.map((x) => {
        return {
          text: x.isSystemRole
            ? this.$t("pages.users.form.systemRole", { role: x.name })
            : x.name,
          value: x.id,
        };
      });
    },
    role() {
      return this.availableRoles.find((x) => x.id === this.form.roleId);
    },
    roleClaims() {
      return this.role ? this.role.roleClaims.map((x) => x.claimType) : [];
    },
  },
  methods: {
    isOrganizationRoles() {
      return this.roleClaims.includes("Primeportal");
    },
    isBackofficeRoles() {
      return this.roleClaims.includes("Backoffice");
    },
    isSystemRole() {
      return this.role ? this.role.isSystemRole : false;
    },
    isGlobalRole() {
      return this.role ? this.role.organizationId == null : false;
    },
    isSupportRole() {
      return this.role ? this.role.id === systemRoles.support : false;
    },
    isOrganizationChanged() {
      if (!this.user?.id || this.user.id === EMPTY_GUID) return false;

      let newRole = this.availableRoles.find((x) => x.id == this.form.roleId);
      if (!newRole) return false;

      let previousRole = this.availableRoles.find(
        (x) => x.id == this.user.roleId
      );

      return (
        previousRole.isSystemRole !== newRole.isSystemRole ||
        (!previousRole.isSystemRole &&
          previousRole.organizationId != newRole.organizationId)
      );
    },
    submit() {
      if (this.user.id && this.user.id !== EMPTY_GUID) {
        this.form.put(this.route("users.update", this.user.id), {
          preserveScroll: true,
          preserveState: true,
        });
      } else {
        this.form.post(this.route("users.store"));
      }
    },
    deactivate() {
      this.$inertia.delete(this.route("users.destroy", this.user.id), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
    reactivate() {
      this.$inertia.post(this.route("users.restore", this.user.id), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
    toggleSelectAllLocations() {
      if (!this.allLocationsSelected) {
        this.form.locations = this.organizationLocations.map((l) => l.id);
      } else {
        this.form.locations = [];
      }
    },
    fixSelectAllLocationsCheck() {
      setTimeout(() => {
        const tempLocations = this.form.locations;
        this.form.locations = [];

        this.$nextTick(() => {
          this.form.locations = tempLocations;
        });
      }, 25);
    },
    fetchRoles() {
      this.rolesLoading = true;

      fetch(this.route("organization.roles"), {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          this.availableRoles = data;
          if (!this.isGlobalRole(this.form.roleId)) {
            this.form.roleId = null;
          }
        })
        .finally(() => (this.rolesLoading = false));
    },
    addNewGroup() {
      const inputString = String(this.groupSearchInput).trim();
      this.groupSearchInput = "";

      if (inputString === "") {
        return;
      }
      this.availableGroups.push({ name: inputString, id: null });
      this.form.groups = [...this.form.groups, inputString];
    },
  },
};
</script>
