export const fixedClasses = {
  generalId: "27d17caf-8ab8-451a-98df-1ae9e5dd16f8",
  addressId: "4316119b-f7d6-431c-8373-1b6fdd7efd6e",
  solutionsId: "dad8a566-b8e4-4031-8c6e-b66b56afeb0b",
};

export const fixedAttributes = {
  generalLocationNumberId: "cdc29214-8014-4cf3-9ae3-0036e6a5a24e",
  generalLocationNameId: "559c7edd-cf73-49a0-876d-b75aad6731bc",
  generalLocationTypeId: "361170ee-1dbe-4894-beef-c15439fa36ec",
  generalDescriptionId: "3256bfd5-9690-4494-a27f-167c1d9193a9",
  generalEmailId: "0779512c-2654-4df2-b6b1-14799f203bd7",
  generalPhoneNumberId: "34ed7f27-133d-46f2-89a3-7211ae0b54ca",
  generalInstalledAtId: "cef505d4-e1f0-4000-b57f-2b36295a1c5f",
  generalRegionId: "9dcfd3e9-f478-476c-91c5-09c9c552a583",
  addressCountryId: "b36a51b0-466c-4e92-b9ff-d3f06f724eeb",
  addressAddressLine1Id: "5b755069-6568-4409-9f5d-4feb3a83c82e",
  addressAddressLine2Id: "a831196c-0e29-4532-8e84-e4d46f3a5a9b",
  addressPostalCodeId: "46510641-08b9-4adb-b17c-c36267f652ac",
  addressCityId: "03c83e00-0b9f-4f7e-a455-29b7da5284b4",
  addressStateId: "1e969a98-dd25-41ed-9691-559f4f1dc53e",
};

export const fixedFieldAttributes = {
  generalCreatedById: "450e3605-6bc1-4b19-be3f-78a794c8137c",
  generalUpdatedById: "dbb56bec-16fe-45ff-9669-c446b03fbb94",
  generalCreatedAtId: "22f2bf36-b487-4472-be03-abb4e7450b74",
  generalUpdatedAtId: "fbf80673-bacc-4f3c-b48b-a436a6f4f91c",
  addressLatitudeId: "3d97fa8b-016e-43a2-9706-1c6a46ae763f",
  addressLongitudeId: "5ea6c339-f97e-42a6-ba71-453f89f0b305",
};
