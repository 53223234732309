import { render, staticRenderFns } from "./Conversations.vue?vue&type=template&id=2a00e96b&scoped=true"
import script from "./Conversations.vue?vue&type=script&lang=js"
export * from "./Conversations.vue?vue&type=script&lang=js"
import style0 from "./Conversations.vue?vue&type=style&index=0&id=2a00e96b&prod&scoped=true&lang=css"
import style1 from "@vueform/multiselect/themes/default.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a00e96b",
  null
  
)

export default component.exports