<template>
  <page-modal
    v-model="show"
    :title="modalTitle"
    cardStyle="min-height: 90vh"
    cardTextClass="d-flex flex-column"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="ml-4 mb-2"
        height="3rem"
        text
        color="primary"
        @click="$emit('clear')"
      >
        <div class="d-flex flex-column justify-center align-center">
          <v-icon class="black--text">mdi-plus</v-icon>
          <p class="teal--text text--darken-4 mb-0">
            {{ $t("pages.solutions.form.create") }}
          </p>
        </div>
      </v-btn>
    </template>
    <template #body>
      <v-tabs v-model="tab" show-arrows class="flex-grow-0">
        <v-tab
          v-for="attributeClass in attributeClassTabs"
          :key="attributeClass.id"
        >
          {{ attributeClass.name }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="min-height: calc(100% - 48px)"
        :class="tabItemsClass"
        class="flex-grow-1"
      >
        <template v-for="attributeClass in attributeClassTabs">
          <tab-static-master-data
            v-if="attributeClass.id === fixedClasses.masterDataId"
            :key="`master-${attributeClass.id}`"
            :isTemplatePage="isTemplatePage"
            :attributes="attributes"
            :solution="solution"
            :solutionName="form.name"
            :locationId="form.locationId"
            :solutionTemplateId="form.solutionTemplateId"
            :quantity="form.quantity"
            :locations="locations"
            :solutionTemplates="solutionTemplates"
            @updateForm="updateForm"
          />
          <tab-static-assets
            v-else-if="attributeClass.id === fixedClasses.assetsId"
            :key="`assets-${attributeClass.id}`"
            :solution="solution"
            :solutionName="form.name"
            :locationId="form.locationId"
            :solutionTemplateId="form.solutionTemplateId"
            :quantity="form.quantity"
            :locations="locations"
            :solutionTemplates="solutionTemplates"
            :assetTemplates="assetTemplates"
            :copiedAssets="copiedAssets"
            :selectedTemplateIds="form.assetTemplatesToCreate"
            :deletedAssetIds="form.assetsToDelete"
            @updateForm="updateForm"
            @assetTemplateAdded="handleAssetTemplateAdded"
            @assetRemoved="handleAssetRemoved"
          />
          <tab-static-sla
            v-else-if="attributeClass.id === fixedClasses.slaId"
            :key="`sla-${attributeClass.id}`"
            :attributeLinkData="solutionAttributeLinkData"
            :attributes="attributes"
            :solution="solution"
            @attribute_link_data_changed="updateAttributeLinkData"
            @dateTime_changed="handleDateTimeChanged"
          />
          <tab-static-legacy
            v-else-if="attributeClass.id === fixedClasses.legacyId"
            :key="`legacy-${attributeClass.id}`"
            :contents="form.contents"
            :slaContents="form.slaContents"
            :formErrors="form.errors"
            :visibilities="visibilities"
            @updateForm="updateForm"
          />
          <tab-dynamic
            v-else
            :key="`dynamic-${attributeClass.id}`"
            :attributeClass="attributeClass"
            :attributeLinkData="solutionAttributeLinkData"
            :attributes="attributes"
            :currencies="currencies"
            @bool_changed="handleBoolChanged"
            @currencyCode_changed="handleCurrencyChanged"
            @currency_changed="handleCurrencyChanged"
            @dateTime_changed="handleDateTimeChanged"
            @decimal_changed="handleDecimalChanged"
            @select_changed="handleSelectChanged"
            @string_changed="handleStringChanged"
            @file_changed="handleFileChanged"
            @file_removed="handleFileRemoved"
            @attribute_link_data_changed="updateAttributeLinkData"
            @attribute_link_data_second_value_changed="
              updateAttributeLinkDataSecondValue
            "
          />
        </template>
      </v-tabs-items>
    </template>
    <template #actions:start>
      <modal-button
        v-if="isTemplatePage"
        icon="mdi-content-copy"
        :label="$t('pages.solutions.form.createSolutionFromTemplate')"
        :disabled="!solution || !solution.id"
        @click="confirmTemplateNavigation()"
      />
    </template>
    <template #actions>
      <modal-button
        icon="mdi-close"
        :label="$t('common.actions.close')"
        @click="show = false"
      />
      <modal-button
        icon="mdi-content-save-outline"
        :label="$t('common.actions.save')"
        :loading="loading"
        @click="saveSolution(false)"
      />
      <modal-button
        icon="mdi-content-save-outline"
        :label="$t('common.actions.saveAndClose')"
        :loading="loading"
        @click="saveSolution"
      />
    </template>
  </page-modal>
</template>
<script>
import PageModal from "../../Components/PageModal.vue";
import ModalButton from "../../Components/ModalButton.vue";
import { generatePredefinedAttributeLinks } from "../../util/attributeForm";
import { fixedClasses } from "../../util/fixedSolutionData";
import TabDynamic from "./Form/TabDynamic.vue";
import TabStaticLegacy from "./Form/TabStaticLegacy.vue";
import TabStaticMasterData from "./Form/TabStaticMasterData.vue";
import TabStaticAssets from "./Form/TabStaticAssets.vue";
import TabStaticSla from "./Form/TabStaticSla.vue";
import { addTimezoneOffset, removeTimezoneOffset } from "../../util/dateTime";
import { serialize } from "object-to-formdata";

export default {
  components: {
    PageModal,
    ModalButton,
    TabDynamic,
    TabStaticLegacy,
    TabStaticMasterData,
    TabStaticAssets,
    TabStaticSla,
  },
  props: {
    value: Boolean,
    solution: Object,
    attributes: Array,
    attributeClasses: Array,
    currencies: Array,
    locations: Array,
    isTemplatePage: Boolean,
    visibilities: Array,
    solutionTemplates: Array,
    assetTemplates: Object,
  },
  data() {
    const [solutionAttributeLinkData, solutionAttributeLinks] =
      generatePredefinedAttributeLinks(this.attributes);

    return {
      loading: false,
      tab: null,
      solutionAttributeLinkData,
      form: this.$inertia.form({
        id: null,
        name: null,
        locationId: null,
        solutionTemplateId: null,
        quantity: null,
        solutionAttributeLinks,
        contents: null,
        slaContents: null,
        assetTemplatesToCreate: [],
        assetsToDelete: [],
      }),
      boolValueOptions: [
        { value: true, text: this.$t("common.boolean.true") },
        { value: false, text: this.$t("common.boolean.false") },
      ],
      fixedClasses,
      copiedAssets: [],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    modalTitle() {
      if (!this.solution?.id && !this.isTemplatePage)
        return this.$t("pages.solutions.form.title");
      else if (!this.solution?.id)
        return this.$t("pages.solutions.form.templateTitle");

      const solutionName = this.solution?.name ?? "";

      return `#${this.solution.referenceKey} ${solutionName}`;
    },
    attributeClassTabs() {
      const solutionHasContents =
        this.solution?.contents?.length > 0 ||
        this.solution?.slaContents?.length > 0;

      const attributeClasses = this.attributeClasses.filter(
        (x) => solutionHasContents || x.id !== fixedClasses.legacyId
      );

      if (!this.attributes.some((x) => x.attributeClassId == null))
        return attributeClasses;
      return [
        ...attributeClasses,
        { name: this.$t("pages.solutions.form.noClass"), id: null },
      ];
    },
    tabItemsClass() {
      let elementClasses = [];

      if (this.attributeClassTabs[this.tab]?.id === fixedClasses.legacyId)
        elementClasses.push("bg-transparent");

      return elementClasses.join(" ");
    },
  },
  methods: {
    setForm(solution) {
      const formValues = {};

      if (solution !== null) {
        formValues.id = solution.id;
        formValues.name = solution.name;
        formValues.locationId = solution.locationId;
        formValues.solutionTemplateId = solution.solutionTemplateId;
        formValues.quantity = solution.quantity;
        this.setAttributeLinkData(solution.solutionAttributeLinks ?? []);
        formValues.solutionAttributeLinks =
          solution.solutionAttributeLinks?.map((x) => {
            let stringValue = x.stringValue;

            if (
              !stringValue &&
              x.attribute?.format === "Currency" &&
              !!this.defaultCurrencyCode
            )
              stringValue = this.defaultCurrencyValue;

            return {
              attributeId: x.attributeId,
              stringValue: stringValue,
              dateTimeValue: x.dateTimeValue,
              decimalValue: x.decimalValue,
              boolValue: x.boolValue,
              fileId: x.fileId,
              fileIds: x.solutionAttributeFileLinks
                ? x.solutionAttributeFileLinks.map((y) => y.fileId)
                : [],
              attributeSelectOptionId: x.attributeSelectOptionId,
            };
          }) ?? [];
        formValues.contents = solution.contents;
        formValues.slaContents = solution.slaContents.map((x) => ({
          ...x,
          startsAt: x.startsAt?.substring(0, 10) ?? null,
          endsAt: x.endsAt?.substring(0, 10) ?? null,
        }));

        if (solution.fromTemplate) {
          this.copiedAssets = solution.copiedAssets ?? [];

          formValues.assetTemplatesToCreate = this.copiedAssets.map(
            (x) => x.id
          );
        }
      } else {
        formValues.id = null;
        formValues.name = null;
        formValues.locationId = null;
        formValues.solutionTemplateId = null;
        formValues.quantity = null;
        formValues.solutionAttributeLinks = [];
        generatePredefinedAttributeLinks(
          this.attributes,
          this.solutionAttributeLinkData,
          formValues.solutionAttributeLinks
        );
        formValues.contents = null;
        formValues.slaContents = null;
      }

      formValues.assetTemplatesToCreate =
        formValues.assetTemplatesToCreate ?? [];
      formValues.assetsToDelete = formValues.assetsToDelete ?? [];

      this.form = this.$inertia.form(formValues);
    },
    setAttributeLinkData(attributeLinks) {
      this.attributes?.forEach((attribute) => {
        var attributeLink = attributeLinks.find(
          (x) => x.attributeId == attribute.id
        );
        if (attributeLink) {
          switch (attribute.format) {
            case "Text":
              this.solutionAttributeLinkData[attribute.id] =
                attributeLink.stringValue;
              break;
            case "TextArea":
              this.solutionAttributeLinkData[attribute.id] =
                attributeLink.stringValue;
              break;
            case "Select":
              this.solutionAttributeLinkData[attribute.id] =
                attributeLink.attributeSelectOptionId;
              break;
            case "Number":
            case "Percentage":
              this.solutionAttributeLinkData[attribute.id] =
                attributeLink.decimalValue;
              break;
            case "DateTime":
            case "Date":
              this.solutionAttributeLinkData[attribute.id] = addTimezoneOffset(
                new Date(attributeLink.dateTimeValue + "Z")
              );
              break;
            case "Currency":
              this.solutionAttributeLinkData[attribute.id + "-secondValue"] =
                attributeLink.stringValue ?? this.defaultCurrencyCode ?? null;
              this.solutionAttributeLinkData[attribute.id] =
                attributeLink.decimalValue;
              break;
            case "Boolean":
              this.solutionAttributeLinkData[attribute.id] =
                attributeLink.boolValue;
              break;
            case "File": {
              let files = [];
              if (attributeLink.file) {
                files.push(attributeLink.file);
              }

              files = files.concat(
                attributeLink.solutionAttributeFileLinks.map((x) => x.file)
              );

              this.solutionAttributeLinkData[attribute.id + "-secondValue"] =
                files;
              break;
            }
            default:
              break;
          }
        } else {
          this.solutionAttributeLinkData[attribute.id] = null;
          if (attribute.format == "File" || attribute.format == "Currency") {
            this.solutionAttributeLinkData[attribute.id + "-secondValue"] = null;
          }
        }
      });
    },
    setAttributeLinkValue(
      attribute,
      key,
      value,
      secondKey = null,
      secondValue = null
    ) {
      if (
        (value == null && secondValue == null) ||
        (Number.isNaN(value) && !secondValue) ||
        (value === "" && !secondValue)
      ) {
        this.form.solutionAttributeLinks =
          this.form.solutionAttributeLinks.filter(
            (x) => x.attributeId !== attribute.id
          );
        return;
      }

      if (Number.isNaN(value) && !!secondValue) {
        value = null;
      }

      if (key === "dateTimeValue") value = removeTimezoneOffset(value);

      let attributeLink = this.form.solutionAttributeLinks.find(
        (x) => x.attributeId === attribute.id
      );

      if (!attributeLink) {
        attributeLink = { attributeId: attribute.id };

        this.form.solutionAttributeLinks.push(attributeLink);
      }

      attributeLink[key] = value;

      if (secondKey) attributeLink[secondKey] = secondValue;
    },
    handleBoolChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "boolValue", value);
    },
    handleDateTimeChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "dateTimeValue", value);
    },
    handleDecimalChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "decimalValue", value);
    },
    handleSelectChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "attributeSelectOptionId", value);
    },
    handleStringChanged(value, attribute) {
      this.setAttributeLinkValue(attribute, "stringValue", value);
    },
    handleCurrencyChanged(value, currencyCode, attribute) {
      if (!currencyCode && !!this.defaultCurrencyCode)
        currencyCode = this.defaultCurrencyCode;

      this.setAttributeLinkValue(
        attribute,
        "decimalValue",
        value,
        "stringValue",
        currencyCode
      );
    },
    handleFileChanged(value, attribute) {
      if (value == null || value.length === 0) {
        const solutionAttributeLink = this.form.solutionAttributeLinks.find(
          (x) => x.attributeId === attribute.id
        );

        if (!solutionAttributeLink.fileIds.length) {
          this.form.solutionAttributeLinks =
            this.form.solutionAttributeLinks.filter(
              (x) => x.attributeId != attribute.id
            );
        }

        return;
      }

      const newFiles = value.length === undefined ? [value] : value;

      let solutionAttributeLink = this.form.solutionAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );
      if (solutionAttributeLink != null) {
        solutionAttributeLink.newFiles = newFiles;
      } else {
        this.form.solutionAttributeLinks.push({
          attributeId: attribute.id,
          newFiles: newFiles,
        });
      }
    },
    handleFileRemoved(fileId, attribute) {
      let solutionAttributeLink = this.form.solutionAttributeLinks.find(
        (x) => x.attributeId == attribute.id
      );

      if (!solutionAttributeLink) return;

      solutionAttributeLink.fileIds = solutionAttributeLink.fileIds.filter(
        (x) => x !== fileId
      );

      if (!solutionAttributeLink.fileIds.length) {
        this.form.solutionAttributeLinks =
          this.form.solutionAttributeLinks.filter(
            (x) => x.attributeId !== attribute.id
          );

        this.solutionAttributeLinkData[attribute.id + "-secondValue"] = null;

        return;
      }

      this.solutionAttributeLinkData[attribute.id + "-secondValue"] =
        this.solutionAttributeLinkData[attribute.id + "-secondValue"].filter(
          (x) => x.id !== fileId
        );
    },
    handleAssetTemplateAdded(templateId) {
      this.form.assetTemplatesToCreate.push(templateId);
    },
    handleAssetRemoved(assetId) {
      if (this.form.assetTemplatesToCreate.includes(assetId))
        this.form.assetTemplatesToCreate =
          this.form.assetTemplatesToCreate.filter((x) => x !== assetId);
      else if (!this.form.assetsToDelete.includes(assetId)) {
        this.form.assetsToDelete.push(assetId);
      }
    },
    updateAttributeLinkData(attributeId, value) {
      this.solutionAttributeLinkData[attributeId] = value;
    },
    updateAttributeLinkDataSecondValue(attributeId, value) {
      this.solutionAttributeLinkData[attributeId + "-secondValue"] = value;
    },
    updateForm(key, value) {
      this.form[key] = value;
    },
    saveSolution(close = true) {
      if (this.loading) return;

      this.loading = true;

      const preparedForm = this.form.transform((data) => {
        const transformedData = {
          ...data,
          contents: data.contents?.map((x, i) => ({
            ...x,
            sortOrder: i + 1,
          })),
          slaContents: data.slaContents?.map((x, i) => ({
            ...x,
            sortOrder: i + 1,
          })),
          template: this.isTemplatePage,
        };

        return serialize(transformedData, {
          dotsForObjectNotation: true,
          indices: true,
          nullsAsUndefineds: true,
          noFilesWithArrayNotation: true,
        });
      });

      if (this.form.id) {
        preparedForm.put(this.route("solutions.update", this.solution.id), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) {
              this.setForm(null);
              this.$emit("reload");
              return;
            }

            this.show = false;
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      } else {
        preparedForm.post(this.route("solutions.store"), {
          preserveScroll: true,
          onSuccess: () => {
            if (!close) {
              this.setForm(null);
              this.$emit("reload");
              return;
            }

            this.show = false;
            this.$emit("clear");
          },
          onFinish: () => {
            this.loading = false;
          },
        });
      }
    },
    confirmTemplateNavigation() {
      if (
        !this.solution ||
        (this.form.isDirty &&
          !confirm(this.$t("pages.solutions.form.confirmLeave")))
      )
        return;

      const url = this.route("solutions.index", {
        templateId: this.solution.id,
      });

      this.$inertia.get(url);
    },
  },
  watch: {
    solution: function (solution) {
      this.setForm(solution);
    },
    show(value) {
      if (!value) this.setForm(null);
    },
  },
};
</script>
<style scoped>
.bg-transparent {
  background-color: transparent;
}
</style>
