<template>
  <v-sheet color="white" class="d-flex fill-height flex-column">
    <v-toolbar class="flex-shrink-1 flex-grow-0">
      <v-select
        class="toolbar-select flex-grow-0 mr-6"
        :label="$t('components.supportCases.conversations.assignee')"
        :items="users"
        :value="selectedCase.assigneeId"
        item-text="name"
        item-value="id"
        @change="(value) => $emit('editCase', { assigneeId: value })"
        :disabled="editForm.processing"
      />
      <v-select
        class="toolbar-select flex-grow-0"
        :label="$t('components.supportCases.conversations.status')"
        :items="formattedStatuses"
        item-text="text"
        item-value="value"
        :value="selectedCase.status"
        @change="attemptChangeStatus"
        :disabled="editForm.processing"
      />
      <v-dialog v-model="showStatusDialog" max-width="350">
        <v-card>
          <v-card-title>{{
            $t("components.supportCases.conversations.closeCase")
          }}</v-card-title>
          <v-card-text>
            {{ $t("components.supportCases.conversations.confirmClose") }}
            <p class="mb-0">
              {{ selectedCase.name }} [{{ selectedCase.referenceKey }}]
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="teal darken-3" text @click="showStatusDialog = false">
              {{ $t("common.actions.cancel") }}
            </v-btn>
            <v-btn
              color="teal darken-3"
              text
              @click="() => changeStatus('Closed')"
            >
              {{ $t("common.actions.ok") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer />
      <support-case-reminder-dialog :selectedCase="selectedCase" />
      <support-case-action-menu
        :organizations="organizations"
        :solutions="solutions"
        :locations="locations"
        :assets="assets"
        :regions="regions"
        :employees="employees"
        :allCases="allCases"
        :selectedCase="selectedCase"
        @update-case="(value) => $emit('editCase', value)"
        @apply-organization-filter="applyAssignMenuOrganizationFilter"
        @apply-all-cases-search-filter="applyAllCasesSearchFilter"
        :disabled="editForm.processing"
      />
    </v-toolbar>
    <div class="px-4 py-4 grey lighten-4 mt-auto">
      {{ $t("components.supportCases.conversations.to") }}
      <multiselect
        v-model="multiselectTo"
        :mode="'tags'"
        :options="recipients"
        :createOption="true"
        :addOptionOn="['enter']"
        :searchable="true"
        :placeholder="$t('components.supportCases.conversations.to')"
        @select="toSelected"
        class="multiselect-custom grey lighten-2"
      >
        <template v-slot:tag="{ option }">
          <div class="multiselect-tag">
            {{ option.label }}
            <span class="multiselect-tag-remove"> </span>
          </div>
        </template>
      </multiselect>
      {{ $t("components.supportCases.conversations.cc") }}
      <multiselect
        v-model="messageForm.cc"
        :mode="'tags'"
        :options="recipients"
        :createOption="true"
        :addOptionOn="['enter']"
        :searchable="true"
        :placeholder="$t('components.supportCases.conversations.cc')"
        class="multiselect-custom grey lighten-2"
      >
        <template v-slot:tag="{ option, handleTagRemove }">
          <div class="multiselect-tag">
            {{ option.label }}
            <span
              class="multiselect-tag-remove"
              @mousedown.prevent="handleTagRemove(option, $event)"
            >
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
      </multiselect>
      {{ $t("components.supportCases.conversations.bcc") }}
      <multiselect
        v-model="messageForm.bcc"
        :mode="'tags'"
        :options="recipients"
        :createOption="true"
        :addOptionOn="['enter']"
        :searchable="true"
        :placeholder="$t('components.supportCases.conversations.bcc')"
        class="multiselect-custom grey lighten-2"
      >
        <template v-slot:tag="{ option, handleTagRemove }">
          <div class="multiselect-tag is-user">
            {{ option.label }}
            <span
              class="multiselect-tag-remove"
              @mousedown.prevent="handleTagRemove(option, $event)"
            >
              <span class="multiselect-tag-remove-icon"></span>
            </span>
          </div>
        </template>
      </multiselect>
    </div>
    <div class="px-4 py-2 grey lighten-4 mt-auto">
      <v-switch
        v-model="showInternalNoteInput"
        :label="
          showInternalNoteInput
            ? $t('components.supportCases.conversations.internalNoteSwitch.on')
            : $t('components.supportCases.conversations.internalNoteSwitch.off')
        "
        hide-details
        class="mt-0"
      ></v-switch>
    </div>
    <div
      class="px-4 pt-3 grey mt-auto"
      :class="showInternalNoteInput ? 'lighten-2' : 'lighten-4'"
    >
      <v-row no-gutters>
        <v-col>
          <v-file-input
            multiple
            dense
            hide-details
            :label="$t('components.supportCases.conversations.attachments')"
            v-model="messageForm.files"
            :disabled="messageForm.processing"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip v-if="index < 2" label small>
                {{ text }}
              </v-chip>
              <span v-else-if="index === 2" class="overline mx-2">
                {{
                  $t("components.supportCases.conversations.additionalFiles", {
                    amount: messageForm.files.length - 2,
                  })
                }}
              </span>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="3" class="ml-2">
          <v-checkbox
            v-model="sendMessageOnEnter"
            :label="$t('components.supportCases.conversations.enterToSend')"
            :disabled="messageForm.processing"
            hide-details
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    <div v-if="showInternalNoteInput" class="px-4 py-2 grey lighten-2 mt-auto">
      <member-tag-v-textarea
        :label="$t('components.supportCases.conversations.addInternalNote')"
        :users="users"
        :groups="groups"
        v-model="messageForm.internalNote"
        :disabled="messageForm.processing"
        :sendOnEnter="sendMessageOnEnter"
        @submit="addInternalNote"
      />
    </div>
    <div v-else class="px-4 py-2 grey lighten-4 mt-auto">
      <v-textarea
        :label="$t('components.supportCases.conversations.addMessage')"
        type="text"
        rows="3"
        no-details
        outlined
        hide-details
        v-model="messageForm.message"
        append-outer-icon="mdi-send"
        @click:append-outer="addMessage"
        @keydown.enter="() => sendMessageOnEnter && addMessage()"
        :disabled="messageForm.processing"
      />
    </div>
    <v-dialog v-model="messageAlertDialog.show" max-width="350">
      <v-card>
        <v-card-title>
          <v-icon color="red" class="mr-2">mdi-alert-circle</v-icon>
          {{ messageAlertDialog.title }}
        </v-card-title>
        <v-card-text>
          {{ messageAlertDialog.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="teal darken-3"
            text
            @click="messageAlertDialog.show = false"
          >
            {{ $t("common.actions.ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      class="overflow-y-auto flex-grow-1 flex-shrink-1 px-4 pt-1 height-0 conversation-container"
      ref="conversation"
    >
      <div class="d-flex flex-column-reverse">
        <div
          v-for="(conversations, date, index) in groupedConversationByDate"
          :key="index"
          class="fill-width pb-2 d-flex flex-column"
        >
          <div class="d-flex align-center mt-4">
            <v-divider />
            <p class="overline mb-0 mx-2">{{ localeDate(date) }}</p>
            <v-divider />
          </div>
          <div class="align-end d-flex flex-column-reverse">
            <div
              v-for="item in conversations"
              :class="{ 'align-self-start': isWrittenByUser(item) }"
              :key="item.id"
              class="d-flex flex-column align-end pb-4 max-w-4/5"
            >
              <div
                class="subtitle-2 mb-0 d-flex"
                :class="{
                  'align-self-start': isWrittenByUser(item),
                  'flex-row-reverse': !isWrittenByUser(item),
                }"
              >
                <p class="mb-0">
                  {{ localeTime(item.createdAt) }}
                </p>
                <p class="mb-0 mx-1">-</p>
                <p class="mb-0">
                  {{ item.authorName }}
                </p>
                <template v-if="!item.isNote && getPrimaryRecipient(item)">
                  <p class="mx-2 mb-0">
                    <v-icon small>{{
                      isWrittenByUser(item)
                        ? "mdi-arrow-right"
                        : "mdi-arrow-left"
                    }}</v-icon>
                  </p>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <p class="mb-0" v-bind="attrs" v-on="on">
                        {{ getPrimaryRecipient(item).email }}
                      </p>
                    </template>
                    <div>
                      <template v-if="getToRecipients(item).length > 0">
                        <h4>
                          {{ $t("components.supportCases.conversations.to") }}:
                        </h4>
                        <ul>
                          <li
                            v-for="recipient in getToRecipients(item)"
                            :key="recipient.id"
                          >
                            {{ recipient.email }}
                          </li>
                        </ul>
                      </template>
                      <template v-if="getCcRecipients(item).length > 0">
                        <h4>
                          {{ $t("components.supportCases.conversations.cc") }}:
                        </h4>
                        <ul>
                          <li
                            v-for="recipient in getCcRecipients(item)"
                            :key="recipient.id"
                          >
                            {{ recipient.email }}
                          </li>
                        </ul>
                      </template>
                      <template v-if="getBccRecipients(item).length > 0">
                        <h4>
                          {{ $t("components.supportCases.conversations.bcc") }}:
                        </h4>
                        <ul>
                          <li
                            v-for="recipient in getBccRecipients(item)"
                            :key="recipient.id"
                          >
                            {{ recipient.email }}
                          </li>
                        </ul>
                      </template>
                    </div>
                  </v-tooltip>
                </template>
              </div>
              <v-sheet
                elevation="2"
                rounded
                class="pa-2 d-inline-block"
                :class="{ 'align-self-start': isWrittenByUser(item) }"
              >
                <div v-if="item.isNote">
                  <div class="overline text-center">
                    {{
                      $t("components.supportCases.conversations.internalNote")
                    }}
                  </div>
                  <v-divider class="mb-2"></v-divider>
                </div>
                <span
                  class="render-whitespace break-word"
                  v-html="formatHtml(item.message ? item.message : '')"
                />
                <div v-if="item.files && item.files.length">
                  <div class="overline">
                    {{
                      $t("components.supportCases.conversations.attachments")
                    }}:
                  </div>
                  <a
                    :href="
                      route('api.files.content', {
                        id: file.id,
                        filename: file.originalName,
                      })
                    "
                    target="_blank"
                    v-for="file in item.files"
                    :key="file.id"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <img
                          v-if="file.fileType == 'image'"
                          :src="
                            route('api.files.content', {
                              id: file.id,
                              filename: file.originalName,
                              thumbnail: !!file.thumbnailUrl
                            })
                          "
                          class="cursor-pointer my-1 mr-2 rounded"
                          style="max-height: 96px;"
                          v-bind="attrs"
                          v-on="on"
                        />
                        <v-chip
                          v-else
                          label
                          link
                          color="blue lighten-1"
                          class="my-1 mr-2 message-attachment"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span class="text-truncate">
                            {{ file.name }}{{ file.extension }}
                          </span>
                        </v-chip>
                      </template>
                      <span>{{ file.name }}{{ file.extension }}</span>
                    </v-tooltip>
                  </a>
                </div>
              </v-sheet>
            </div>
          </div>
        </div>
      </div>
      <div v-if="caseHasUnloadedMessages" class="d-flex align-center my-4">
        <v-divider />
        <v-btn
          rounded
          color="white"
          class="mx-4"
          @click="fetchOlderMessages"
          :loading="olderMessagesLoading"
          :disabled="olderMessagesLoading"
        >
          <v-icon color="teal darken-3">mdi-chevron-double-down</v-icon>
          <span>
            {{ $t("components.supportCases.conversations.expand") }}
          </span>
          <v-icon color="teal darken-3">mdi-chevron-double-down</v-icon>
        </v-btn>
        <v-divider />
      </div>
    </div>
  </v-sheet>
</template>
<script>
import SupportCaseReminderDialog from "@/Components/SupportCase/ReminderDialog";
import SupportCaseActionMenu from "@/Components/SupportCase/ActionMenu";

import { objectToFormData } from "@/util/formData";
import { pascalToSpaces, formatHtmlMessage } from "@/util/text";
import bannedAttachmentFileTypes from "@/util/bannedAttachmentFileTypes";
import {
  toLocaleUTCDateString,
  toLocaleUTCTimeString,
  normalizeISOString,
} from "@/util/dateTime";

import MemberTagVTextarea from "@/Components/SupportCase/MemberTagVTextarea";

import flow from "lodash/flow";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import pickBy from "lodash/pickBy";

import Multiselect from "@vueform/multiselect/dist/multiselect.vue2.js";

import qs from "qs";

export default {
  components: {
    Multiselect,
    SupportCaseReminderDialog,
    SupportCaseActionMenu,
    MemberTagVTextarea,
  },
  props: {
    selectedCase: Object,
    statuses: Array,
    users: Array,
    groups: Array,
    organizations: Array,
    locations: Array,
    solutions: Array,
    assets: Array,
    regions: Array,
    employees: Array,
    allCases: Array,
    regionMails: Array,
  },
  data() {
    const uniqueRecipients = [];

    const filteredMessages = this.selectedCase.messages.filter(
      (x) => !x.isNote
    );

    filteredMessages.forEach((message) => {
      message.recipients.forEach((recipient) => {
        if (uniqueRecipients.some((x) => x == recipient.email)) {
          return;
        }
        uniqueRecipients.push(recipient.email);
      });
    });

    let to = filteredMessages
      .slice(-1)[0]
      ?.recipients.find((x) => x.type == "To")?.email;
    if (!to) {
      if (this.selectedCase.fallbackEmail) {
        if (
          !uniqueRecipients.some((x) => x == this.selectedCase.fallbackEmail)
        ) {
          uniqueRecipients.push(this.selectedCase.fallbackEmail);
        }
      }
      to = this.selectedCase.fallbackEmail;
    }
    this.recipients = uniqueRecipients;

    return {
      multiselectTo: to ? [to] : [],
      recipients: uniqueRecipients,
      messageForm: this.$inertia.form({
        to: to,
        cc:
          filteredMessages
            .slice(-1)[0]
            ?.recipients.filter((x) => x.type == "Cc")
            .map((x) => x.email) ?? [],
        bcc:
          filteredMessages
            .slice(-1)[0]
            ?.recipients.filter((x) => x.type == "Bcc")
            .map((x) => x.email) ?? [],
        message: "",
        internalNote: "",
        supportCaseId: "",
        files: [],
      }),
      sendMessageOnEnter: false,
      editForm: this.$inertia.form(),
      showStatusDialog: false,
      messageAlertDialog: {
        show: false,
        title: null,
        text: null,
      },
      showInternalNoteInput: false,
      olderMessagesLoading: false,
      messages: this.selectedCase?.messages ?? [],
    };
  },
  methods: {
    toSelected(value) {
      this.multiselectTo = [value];
      this.messageForm.to = value;
    },
    validateAttachedFiles() {
      let totalFileSize = this.messageForm.files.reduce(
        (total, current) => total + current.size,
        0
      );

      // 20 MiB
      if (totalFileSize > 20971520) {
        this.messageAlertDialog = {
          show: true,
          title: this.$t(
            "components.supportCases.conversations.attachmentsTooLarge"
          ),
          text: this.$t(
            "components.supportCases.conversations.attachmentsOverTotal"
          ),
        };

        return false;
      }

      let includesBannedFileType = this.messageForm.files.some((f) =>
        bannedAttachmentFileTypes.some(
          (ft) => ft === f.name.split(".").slice(-1)[0]
        )
      );

      if (includesBannedFileType) {
        this.messageAlertDialog = {
          show: true,
          title: this.$t(
            "components.supportCases.conversations.attachmentsInvalid"
          ),
          text: this.$t(
            "components.supportCases.conversations.attachmentsNotAllowed"
          ),
        };

        return false;
      }

      return true;
    },
    addMessage() {
      if (!this.validateAttachedFiles() || this.showInternalNoteInput) return;

      const combinedCc = [
        ...new Set([
          ...(this.messageForm.cc ?? []),
          ...(this.messageForm.bcc ?? []),
        ]),
      ];

      const warningMails = this.getRegionMailsIntersection(combinedCc);

      const confirmCcWarning = this.$t(
        "components.supportCases.conversations.confirmCc",
        {
          mails: warningMails.join("\n"),
        }
      );

      if (warningMails.length > 0 && !confirm(confirmCcWarning)) return;

      const timezone =
        Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? null;

      this.messageForm
        .transform((data) =>
          objectToFormData({
            ...data,
            supportCaseId: this.selectedCase.id,
            isNote: "false",
            timezone: timezone,
          })
        )
        .post(this.route("support-case-messages.store", this.selectedCase.id), {
          only: ["users", "statuses", "selectedCase", "supportCases"],
        });

      this.messageForm.message = "";
      this.messageForm.files = [];
    },
    addInternalNote() {
      if (!this.validateAttachedFiles() || !this.showInternalNoteInput) return;

      this.messageForm
        .transform((data) =>
          objectToFormData({
            ...data,
            supportCaseId: this.selectedCase.id,
            message: data.internalNote,
            isNote: "true",
          })
        )
        .post(this.route("support-case-messages.store", this.selectedCase.id), {
          only: ["users", "statuses", "selectedCase", "supportCases"],
        });

      this.messageForm.internalNote = "";
      this.messageForm.files = [];
    },
    attemptChangeStatus(value) {
      if (value === "Closed") {
        if (!this.selectedCase.rootCause) {
          alert(
            this.$t(
              "components.supportCases.conversations.specifyRootCauseToClose"
            )
          );
        } else {
          this.showStatusDialog = true;
        }

        // Force vuetify to update select value (v-model & $forceUpdate had no effect)
        let tempStatus = this.selectedCase.status;
        this.$emit("setSelectedCaseStatus", null);
        this.$nextTick(() => {
          this.$emit("setSelectedCaseStatus", tempStatus);
        });
        return;
      }

      this.changeStatus(value);
    },
    changeStatus(value) {
      this.showStatusDialog = false;
      this.$emit("setSelectedCaseStatus", value);

      this.$emit("editCase", { status: value });
    },
    getCurrentPageQuery() {
      let queryString = window.location.search;
      if (queryString.startsWith("?")) queryString = queryString.substring(1);

      let queryParams = qs.parse(queryString);
      queryParams.filters = queryParams.filters ?? {};

      return {
        page: queryParams.page,
        sortDesc: queryParams.sortDesc,
        selectedCaseId: queryParams.selectedCaseId,
        filterType: queryParams.filterType,
        filters: pickBy(queryParams.filters),
        sortBy: queryParams.sortBy,
      };
    },
    applyAssignMenuOrganizationFilter(organizationId) {
      let currentQuery = this.getCurrentPageQuery();

      currentQuery.selectedOrganizationId = organizationId;

      this.$inertia.get(
        this.route("support-cases.index", currentQuery),
        {},
        {
          only: ["locations", "organizations"],
          preserveState: true,
        }
      );
    },
    applyAllCasesSearchFilter(searchText) {
      let currentQuery = this.getCurrentPageQuery();

      currentQuery.allCasesSearch = searchText;

      this.$inertia.get(
        this.route("support-cases.index", currentQuery),
        {},
        { only: ["allCases"], preserveState: true }
      );
    },
    localeDate(date) {
      let isoDateTime = normalizeISOString(date);

      return toLocaleUTCDateString(isoDateTime);
    },
    localeTime(date) {
      let isoDateTime = normalizeISOString(date);
      let utcDate = new Date(isoDateTime);

      return toLocaleUTCTimeString(utcDate);
    },
    isWrittenByUser(message) {
      let isSupportUser = message.author?.role?.roleClaims.some(
        (r) => r.claimType == "Support"
      );

      let isEmailCase = !message.authorId && this.selectedCase.createdFromEmail;

      return !isSupportUser || isEmailCase;
    },
    formatPascal(text) {
      return pascalToSpaces(text || "");
    },
    formatHtml(text) {
      return formatHtmlMessage(text);
    },
    getPrimaryRecipient(message) {
      if (!message?.recipients) return null;

      const isWrittenByUser = this.isWrittenByUser(message);

      const recipientType = isWrittenByUser ? "From" : "To";
      const targetType = isWrittenByUser ? "To" : "From";

      let recipient =
        message.recipients.find((x) => x.type === recipientType) ?? null;

      const filteredRecipients = message.recipients.filter(
        (x) => x.type !== targetType
      );

      if (recipient === null && filteredRecipients.length > 0) {
        recipient = filteredRecipients[0];
      }

      return recipient;
    },
    getToRecipients(message) {
      if (!message?.recipients) return [];

      const isWrittenByUser = this.isWrittenByUser(message);

      const recipientType = isWrittenByUser ? "From" : "To";

      return message.recipients.filter((x) => x.type === recipientType);
    },
    getCcRecipients(message) {
      if (!message?.recipients) return [];

      return message.recipients.filter((x) => x.type === "Cc");
    },
    getBccRecipients(message) {
      if (!message?.recipients) return [];

      return message.recipients.filter((x) => x.type === "Bcc");
    },
    fetchOlderMessages() {
      let cutoffTimestamp = this.messages[0]?.createdAt;

      if (!cutoffTimestamp) return;

      this.olderMessagesLoading = true;

      fetch(
        this.route("api.support-case-messages.list", {
          supportcaseid: this.selectedCase.id,
          cutoff: cutoffTimestamp,
        })
      )
        .then((res) => res.json())
        .then((data) => {
          this.messages = [...data, ...this.messages];
          this.olderMessagesLoading = false;
        });
    },
    getRegionMailsIntersection(emailList) {
      const cleanedEmailList =
        emailList
          ?.map((x) => x?.toLowerCase().trim() ?? "")
          .filter((x) => !!x) ?? [];

      const cleanedRegionList =
        this.regionMails
          ?.map((x) => x?.toLowerCase().trim() ?? "")
          .filter((x) => !!x) ?? [];

      return cleanedEmailList.filter((x) => cleanedRegionList.includes(x));
    },
  },
  computed: {
    groupedConversationByDate() {
      if (!this.messages) return [];

      return flow(
        (x) => sortBy(x, (x) => x.createdAt),
        (x) => groupBy(x, (x) => x.createdAt.slice(0, 10))
      )(this.messages);
    },
    formattedStatuses() {
      return this.statuses.map((x) => ({
        text: this.$t(`components.supportCases.conversations.statuses.${x}`),
        value: x,
      }));
    },
    caseHasUnloadedMessages() {
      if (!this.messages) return false;

      return this.selectedCase.messageCount > this.messages.length;
    },
  },
  watch: {
    selectedCase() {
      this.messages = this.selectedCase?.messages ?? [];

      const uniqueRecipients = [];

      const filteredMessages = this.messages.filter((x) => !x.isNote);

      filteredMessages.forEach((message) => {
        message.recipients.forEach((recipient) => {
          if (uniqueRecipients.some((x) => x == recipient.email)) {
            return;
          }
          uniqueRecipients.push(recipient.email);
        });
      });

      let to = filteredMessages
        .slice(-1)[0]
        ?.recipients.find((x) => x.type == "To")?.email;
      if (!to) {
        if (this.selectedCase.fallbackEmail) {
          if (
            !uniqueRecipients.some((x) => x == this.selectedCase.fallbackEmail)
          ) {
            uniqueRecipients.push(this.selectedCase.fallbackEmail);
          }
        }
        to = this.selectedCase.fallbackEmail;
      }
      this.recipients = uniqueRecipients;

      this.messageForm.to = to;
      this.multiselectTo = to ? [to] : [];

      this.messageForm.cc =
        filteredMessages
          .slice(-1)[0]
          ?.recipients.filter((x) => x.type == "Cc")
          .map((x) => x.email) ?? [];
      this.messageForm.bcc =
        filteredMessages
          .slice(-1)[0]
          ?.recipients.filter((x) => x.type == "Bcc")
          .map((x) => x.email) ?? [];
    },
  },
};
</script>
<style scoped>
.toolbar-select {
  padding-top: 2rem;
  width: 22%;
}
@media only screen and (max-width: 1263px) {
  .toolbar-select {
    width: 32%;
  }
}
@media only screen and (max-width: 959px) {
  .toolbar-select {
    width: 50%;
  }
}
.message-attachment {
  max-width: 14rem;
}
.render-whitespace {
  white-space: pre-line;
}
.break-all {
  word-break: break-all;
}
.conversation-container {
  min-height: 600px;
}
.height-0 {
  height: 0px;
}

.multiselect-custom {
  --ms-tag-bg: #22746e;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
