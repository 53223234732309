<template>
  <v-card :elevation="elevation" class="mt-4 cursor-grab">
    <v-card-title class="pb-1">
      {{ title }}
      <v-spacer />
      <v-btn icon @click="removeContent"
        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="align-center">
        <v-col cols="4">
          <v-subheader>{{ $t("components.contents.visibility") }}</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-radio-group
            :value="content.visibility"
            row
            class="mt-1 visibility-buttons"
            :error-messages="formErrors[`contents${index}Visibility`]"
            @input="updateContent('visibility', $event)"
          >
            <v-radio
              v-for="visibility in visibilities"
              :key="visibility"
              :label="$t(`common.visibilities.${visibility}`)"
              :value="visibility"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-if="isSla" no-gutters class="align-center">
        <v-col cols="4">
          <v-subheader>{{
            $t("components.contents.activeTimeperiod")
          }}</v-subheader>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="6">
              <v-menu
                v-model="startsAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="startsAt"
                    :label="$t('components.contents.startDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="formErrors[`slaContents${index}StartsAt`]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="startsAt"
                  @input="slaStartsAtChanged($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="endsAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="endsAt"
                    :label="$t('components.contents.endDate')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="formErrors[`slaContents${index}EndsAt`]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :value="endsAt"
                  :min="startsAt"
                  @input="slaEndsAtChanged($event)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <editor
        :value="content.description"
        :disabled="isContentDragging"
        @input="updateContent('description', $event)"
      />
    </v-card-text>
    <v-card-text>
      <v-file-input
        :value="content.newFiles"
        :placeholder="$t('components.contents.addFiles.placeholder')"
        :label="$t('components.contents.addFiles.label')"
        multiple
        small-chips
        prepend-icon="mdi-paperclip"
        @change="updateContent('newFiles', $event)"
      />
    </v-card-text>
    <v-card-text v-if="content.files && content.files.length">
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t("common.fieldNames.name") }}
              </th>
              <th class="text-left">
                {{ $t("common.fieldNames.createdAt") }}
              </th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, fIndex) in content.files" :key="item.id">
              <td>{{ item.name }}{{ item.extension }}</td>
              <td>
                {{ new Date(item.createdAt).toLocaleString() }}
              </td>
              <td class="text-right">
                <v-btn
                  small
                  text
                  @click.stop
                  :href="
                    route('api.files.content', {
                      id: item.id,
                      filename: item.originalName,
                    })
                  "
                  target="_blank"
                >
                  <v-icon class="mr-2">mdi-attachment</v-icon>
                </v-btn>
                <v-btn small text @click.stop="removeFile(fIndex)">
                  <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<script>
import Editor from "@/Shared/Editor";

export default {
  components: { Editor },
  props: {
    content: Object,
    index: Number,
    formErrors: Object,
    elevation: String,
    isSla: Boolean,
    visibilities: Array,
    isContentDragging: Boolean,
  },
  emits: ["updateContent", "removeContent", "removeFile"],
  data() {
    return {
      startsAtMenu: false,
      endsAtMenu: false,
    };
  },
  methods: {
    updateContent(property, value) {
      this.$emit("updateContent", this.index, property, value);
    },
    removeContent() {
      this.$emit("removeContent", this.index);
    },
    removeFile(fileIndex) {
      this.$emit("removeFile", this.index, fileIndex);
    },
    slaStartsAtChanged(value) {
      this.updateContent("startsAt", value);

      let fromDate = new Date(value);
      let toDate = this.content.endsAt ? new Date(this.content.endsAt) : null;

      if (toDate && toDate < fromDate) {
        this.updateContent("endsAt", null);
      }

      this.startsAtMenu = false;
    },
    slaEndsAtChanged(value) {
      this.updateContent("endsAt", value);

      this.endsAtMenu = false;
    },
  },
  computed: {
    title() {
      if (this.isSla) return this.$t("components.contents.sla");

      return this.content.informationType?.name;
    },
    startsAt() {
      if (!this.isSla) return null;

      let value = this.content.startsAt ?? "";

      if (value.length !== 10) return value.substring(0, 10);

      return value;
    },
    endsAt() {
      if (!this.isSla) return null;

      let value = this.content.endsAt ?? "";

      if (value.length !== 10) return value.substring(0, 10);

      return value;
    },
  },
};
</script>
<style>
.editor-content .ProseMirror {
  min-height: 150px;
}
</style>
